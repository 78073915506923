var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('div',{staticClass:"helpArea"},[_c('div',{staticClass:"leftHelpArea"},[_c('v-navigation-drawer',{attrs:{"width":"100%","height":"100%","permanent":""}},[_c('v-list',[_vm._l((_vm.navItems),function(item,i){return [_c('v-list-group',{key:(i + "-b"),on:{"click":function($event){return _vm.navClick(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]},proxy:true}],null,true)},_vm._l((item.subItems),function(items,y){return _c('v-list-item',{key:y,staticClass:"pl-8",on:{"click":function($event){return _vm.navClick(items)}},model:{value:(items.active),callback:function ($$v) {_vm.$set(items, "active", $$v)},expression:"items.active"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(items.name))])],1)],1)}),1)]})],2)],1)],1),_c('div',{staticClass:"rightHelpArea"},[(_vm.navSelected.value === 'start')?_c('startPage',{on:{"navRouteMove":_vm.navRouteMove}}):_vm._e(),(
          _vm.navSelected.value === 'filter' || _vm.navSelected.value === 'common'
        )?_c('filters'):_vm._e(),(_vm.navSelected.value === 'alram')?_c('alarms'):_vm._e(),(_vm.navSelected.value === 'excel')?_c('excels'):_vm._e(),(_vm.navSelected.value === 'print')?_c('prints'):_vm._e(),(_vm.navSelected.value === 'context')?_c('contexts'):_vm._e(),(_vm.navSelected.value === 'main')?_c('mains'):_vm._e(),(_vm.navSelected.value === 'order')?_c('orders'):_vm._e(),(
          _vm.navSelected.value === 'cacluate' ||
          _vm.navSelected.value === 'purchaseCac'
        )?_c('purchaseCac'):_vm._e(),(_vm.navSelected.value === 'salesCac')?_c('salesCac'):_vm._e(),(
          _vm.navSelected.value === 'purchaseEnd' || _vm.navSelected.value === 'end'
        )?_c('purchaseDed'):_vm._e(),(_vm.navSelected.value === 'salesEnd')?_c('salesDed'):_vm._e(),(_vm.navSelected.value === 'vat')?_c('vats'):_vm._e(),(_vm.navSelected.value === 'claim')?_c('claims'):_vm._e(),(_vm.navSelected.value === 'endClaim')?_c('endClaims'):_vm._e(),(
          _vm.navSelected.value === 'setting' ||
          _vm.navSelected.value === 'commonSetting'
        )?_c('commons'):_vm._e(),(_vm.navSelected.value === 'client')?_c('clients'):_vm._e(),(_vm.navSelected.value === 'item')?_c('items'):_vm._e(),(_vm.navSelected.value === 'cloth')?_c('cloths'):_vm._e(),(_vm.navSelected.value === 'inputSetting')?_c('inputSetting'):_vm._e(),(_vm.navSelected.value === 'printInputSetting')?_c('printInputSetting'):_vm._e(),(_vm.navSelected.value === 'excelupload')?_c('exceluploads'):_vm._e(),(_vm.navSelected.value === 'production')?_c('productions'):_vm._e(),(_vm.navSelected.value === 'release')?_c('releases'):_vm._e(),(_vm.navSelected.value === 'appearance')?_c('appearances'):_vm._e(),(_vm.navSelected.value === 'manufacture')?_c('manufactures'):_vm._e(),(_vm.navSelected.value === 'stock')?_c('stock'):_vm._e(),(_vm.navSelected.value === 'useStock')?_c('useStock'):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }