import startPage from "./start.vue";
import filters from "./common/filter.vue";
import alarms from "./common/alarm.vue";
import excels from "./common/excel.vue";
import prints from "./common/print.vue";
import contexts from "./common/context.vue";
import mains from "./main/main.vue";
import orders from "./order/order.vue";
import purchaseCac from "./purchase/purchaseCac.vue";
import salesCac from "./sales/salesCac.vue";
import purchaseDed from "./purchase/purchaseDed.vue";
import salesDed from "./sales/salesDed.vue";
import vats from "./vat/vat.vue";
import claims from "./claim/claim.vue";
import endClaims from "./claim/claimEnd.vue";
import commons from "./setting/common.vue";
import clients from "./setting/client.vue";
import items from "./setting/item.vue";
import cloths from "./setting/cloth.vue";
import inputSetting from "./setting/inputSetting.vue";
import printInputSetting from "./setting/printInputSetting.vue";
import exceluploads from "./setting/excelUpload.vue";
import productions from "./production/production.vue";
import manufactures from "./production/manufacture.vue";
import appearances from "./production/appearance.vue";
import releases from "./production/release.vue";
import stock from "./stock/stock.vue";
import useStock from "./stock/useStock.vue";

export default {
  components: {
    startPage,
    filters,
    alarms,
    prints,
    excels,
    contexts,
    mains,
    orders,
    purchaseCac,
    salesCac,
    purchaseDed,
    salesDed,
    vats,
    claims,
    endClaims,
    commons,
    clients,
    items,
    cloths,
    inputSetting,
    exceluploads,
    productions,
    releases,
    appearances,
    manufactures,
    printInputSetting,
    stock,
    useStock,
  },
  data() {
    return {
      navSelected: { name: "시작하기", value: "start" },
      common: [
        { name: "필터", value: "filters" },
        { name: "알림", value: "alram" },
        { name: "엑셀 다운로드", value: "excel" },
        { name: "인쇄", value: "print" },
      ],
      navItems: [
        {
          to: "",
          name: "시작하기",
          value: "start",
          active: false,
        },
        {
          to: "",
          name: "공통",
          value: "common",
          active: false,
          subItems: [
            { to: "", name: "필터", value: "filter", active: false },
            { to: "", name: "알림", value: "alram", active: false },
            { to: "", name: "엑셀다운로드", value: "excel", active: false },
            { to: "", name: "인쇄", value: "print", active: false },
            { to: "", name: "확장메뉴", value: "context", active: false },
          ],
        },
        {
          to: "",
          name: "메인",
          value: "main",
          active: false,
        },
        {
          to: "",
          name: "오더",
          value: "order",
          active: false,
        },
        {
          to: "",
          name: "생산",
          value: "production",
          active: false,
          subItems: [
            { to: "", name: "생산과정", value: "production" },
            { to: "", name: "가공의뢰서", value: "manufacture" },
            { to: "", name: "출고의뢰서", value: "release" },
            { to: "", name: "외관검사서", value: "appearance" },
          ],
        },
        {
          to: "",
          name: "매입/매출 정산",
          value: "cacluate",

          active: false,
          subItems: [
            { to: "", name: "매입", value: "purchaseCac" },
            { to: "", name: "매출", value: "salesCac" },
          ],
        },
        {
          to: "",
          name: "매입/매출 마감",
          value: "end",
          active: false,
          subItems: [
            { to: "", name: "매입", value: "purchaseEnd" },
            { to: "", name: "매출", value: "salesEnd" },
          ],
        },
        {
          to: "",
          name: "외상금",
          value: "vat",
          active: false,
        },
        {
          to: "",
          name: "클레임",
          value: "claim",
          active: false,
          subItems: [
            { to: "", name: "클레임", value: "claim" },
            { to: "", name: "마감클레임", value: "endClaim" },
          ],
        },
        {
          to: "",
          name: "재고",
          value: "stock",
          active: false,
          subItems: [
            { to: "", name: "재고", value: "stock" },
            // { to: "", name: "사용 재고", value: "useStock" },
          ],
        },
        {
          to: "",
          name: "설정",
          value: "setting",
          active: false,
          subItems: [
            { to: "", name: "기본 설정", value: "commonSetting" },
            { to: "", name: "거래처", value: "client" },
            { to: "", name: "아이템", value: "item" },
            { to: "", name: "복종", value: "cloth" },
            { to: "", name: "엑셀업로드", value: "excelupload" },
            { to: "", name: "입력 설정", value: "inputSetting" },
            { to: "", name: "출력 설정", value: "printInputSetting" },
          ],
        },
      ],
    };
  },
  computed: {},
  methods: {
    navClick(e) {
      this.navSelected = e;
    },
    navRouteMove(target) {
      switch (target) {
        case "order":
          this.navSelected = {
            to: "",
            name: "오더",
            value: "order",
            active: false,
          };
          break;
        case "client":
          this.navSelected = { to: "", name: "거래처", value: "client" };
          break;
        case "item":
          this.navSelected = { to: "", name: "아이템", value: "item" };
          break;
        case "cloth":
          this.navSelected = { to: "", name: "복종", value: "cloth" };
          break;
        case "production":
          this.navSelected = { to: "", name: "생산과정", value: "production" };
          break;
        case "manufacture":
          this.navSelected = {
            to: "",
            name: "가공의뢰서",
            value: "manufacture",
          };
          break;
        case "release":
          this.navSelected = { to: "", name: "출고의뢰서", value: "release" };
          break;
        case "appearance":
          this.navSelected = {
            to: "",
            name: "외관검사서",
            value: "appearance",
          };
          break;
        case "purchaseCac":
          this.navSelected = { to: "", name: "매입", value: "purchaseCac" };
          break;
        case "purchaseEnd":
          this.navSelected = { to: "", name: "매입", value: "purchaseEnd" };
          break;
        case "salesCac":
          this.navSelected = { to: "", name: "매출", value: "salesCac" };
          break;
        case "salesEnd":
          this.navSelected = { to: "", name: "매출", value: "salesEnd" };
          break;
        case "vat":
          this.navSelected = {
            to: "",
            name: "외상금",
            value: "vat",
            active: false,
          };
          break;
        case "claim":
          this.navSelected = { to: "", name: "클레임", value: "claim" };
          break;
        case "endClaim":
          this.navSelected = { to: "", name: "마감클레임", value: "endClaim" };
          break;
      }
    },
  },
  created() {},
};
